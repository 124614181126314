

/**
 * Uses a LRU cache to make a given parametrized function cached.
 * Caches just the last value.
 * The key must be JSON serializable.
*/
export class LRUCachedComputed<TArg, TComputed> {
	private lastCache: TComputed | undefined = undefined;
	private lastArgKey: string | undefined = undefined;

	constructor(private readonly computeFn: (arg: TArg) => TComputed) {
	}

	public get(arg: TArg): TComputed {
		const key = JSON.stringify(arg);
		if (this.lastArgKey !== key) {
			this.lastArgKey = key;
			this.lastCache = this.computeFn(arg);
		}
		return this.lastCache!;
	}
}
